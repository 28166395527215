<template>
  <section :class="['top-banner']" :style="backgroundColor ? `background-color: ${backgroundColor}` : ''">
    <CustomButton
      v-if="(hrefLinker || toLinker || attentiveInfo.enabled) && buttonText"
      class="banner-content banner-content-btn"
      data-banner-button
      :href="hrefLinker"
      :to="toLinker"
      style-name="tertiary"
      style-color="white"
      @click-button="checkAttentiveTrigger"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="buttonText" />
    </CustomButton>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-else-if="buttonText" class="banner-content" v-html="buttonText" />
    <template v-if="(!state.countDownExpired || !state.hideCountdownExpired) && state.hasMounted">
      <div class="divider">|</div>
      <p class="countdown-display">
        {{ state.dateDisplay }}
      </p>
    </template>
  </section>
</template>

<script setup lang="ts">
import { formatNumDigits } from '~/util/string';
import { checkAttentive } from '~/util/attentive';
import { AttentiveTriggerCS, ButtonCS } from '~/types/contentstack';
import { getFutureTime } from '~/util/date';
import { extractInternalLink } from '~/util/contentstack/csHelpers';

type TopBanner = {
  timer_expired_button_link: ButtonCS;
  button_link: ButtonCS;
  content: string;
  title: string;
  countdown_date?: Date;
  hide_banner_date?: Date;
  timer_expired_content?: string;
  timer_expired_link?: string;
  link: string;
};

interface ButtonCSExtended extends ButtonCS {
  backup_link?: string;
  backup_text?: string;
}

const localePath = useLocalePath();

const props = defineProps({
  topBanner: {
    type: Object as () => TopBanner,
    required: true,
  },
});

const state = reactive({
  countdownInterval: 0 as any,
  hideCountdownInterval: 0 as any,
  countDownExpired: false,
  hideCountdownExpired: false,
  dateDisplay: '00 days 00 hours 00 min 00 sec',
  hasMounted: false,
});

const bannerInfo = computed<ButtonCSExtended>(() => {
  if (!state.countDownExpired || !state.hideCountdownExpired) {
    return {
      ...props.topBanner?.button_link,
      backup_link: props.topBanner?.link,
      backup_text: props.topBanner?.content,
    };
  }
  return {
    ...props.topBanner?.timer_expired_button_link,
    backup_link: props.topBanner?.timer_expired_link,
    backup_text: props.topBanner?.timer_expired_content,
  };
});
const attentiveInfo = computed<AttentiveTriggerCS>(() => {
  const fallback: AttentiveTriggerCS = {
    enabled: false,
    desktop_creative_id: '',
    mobile_creative_id: '',
  };
  return bannerInfo.value?.attentive_trigger || fallback;
});
const toLinker = computed(() => {
  const url = attentiveInfo.value.enabled
    ? null
    : extractInternalLink(bannerInfo.value?.internal_linkConnection?.edges?.[0]) || null;
  return url && localePath ? localePath(url) : url;
});
const hrefLinker = computed(() => {
  return attentiveInfo.value.enabled || toLinker.value
    ? undefined
    : bannerInfo.value?.external_link?.href || bannerInfo.value?.backup_link || undefined;
});
const buttonText = computed(() => {
  return bannerInfo.value?.button_text || bannerInfo.value?.backup_text || '';
});
const backgroundColor = computed(() => {
  return props.topBanner?.customize?.background_color;
});

onMounted(() => {
  if (props.topBanner?.countdown_date) {
    const countDownDate = new Date(props.topBanner?.countdown_date).getTime();
    state.countdownInterval = setInterval(() => {
      countdownMovement(countDownDate);
    }, 1000);
    countdownMovement(countDownDate);
  }
  if (props.topBanner?.hide_banner_date) {
    const hideCountDownDate = new Date(props.topBanner?.hide_banner_date).getTime();
    state.hideCountdownInterval = setInterval(() => {
      hideCountdownMovement(hideCountDownDate);
    }, 1000);
    hideCountdownMovement(hideCountDownDate);
  }
  state.hasMounted = true;
});
onUnmounted(() => {
  clearInterval(state.countdownInterval);
  clearInterval(state.hideCountdownInterval);
});

function hideCountdownMovement(hideCountDownDate: number) {
  const { distance } = getFutureTime(hideCountDownDate);

  // If the count down is finished, hide component
  if (distance < 0) {
    clearInterval(state.hideCountdownInterval);
    state.hideCountdownExpired = true;
  }
}
function countdownMovement(countDownDate: number) {
  const { days, hours, minutes, seconds, distance } = getFutureTime(countDownDate);

  // If the count down is finished, hide component
  if (distance < 0) {
    clearInterval(state.countdownInterval);
    state.countDownExpired = true;
  } else {
    state.dateDisplay = `${formatNumDigits(days, 2)} days ${formatNumDigits(hours, 2)} hrs  ${formatNumDigits(
      minutes,
      2
    )} min ${formatNumDigits(seconds, 2)} sec `;
  }
}
function checkAttentiveTrigger(e: any) {
  checkAttentive(e, bannerInfo.value);
}
</script>

<style lang="scss">
.top-banner {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: $color-neutral-cool-900;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.hide {
    display: none;
  }

  .banner-content p,
  .banner-content,
  .countdown-display {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: $color-neutral-white;
    margin: 0;
    padding: 0;
  }

  .banner-content-btn[data-banner-button] {
    text-decoration: underline;
    &:focus-visible,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .divider {
    padding: 0 0.5rem;
    color: $color-neutral-white;
    font-weight: 500;
  }

  @include local-mixins.tablet_and_mobile {
    position: unset;
    flex-direction: column;
    a {
      margin-bottom: 0.5rem;
    }
    .divider {
      display: none;
    }
  }
}
</style>
